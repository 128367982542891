import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/todo/TodoBoard.vue'),
        meta: {
        contentClass: 'kanban-application',
      },
    },
    {
      path: '/inbound',
      name: 'homeInbound',
      component: () => import('@/views/home/HomeInbound.vue'),
      meta: {
        pageTitle: 'Дуудлага авах хуудас',
        breadcrumb: [
          {
            text: 'Ажил бүртгэх /Гаднаас дуудсан/',
            active: true,
          },
        ],
      },
    },
    {
      path: '/outbound',
      name: 'homeOutbound',
      component: () => import('@/views/home/HomeOutbound.vue'),
      meta: {
        pageTitle: 'Гадагшаа залгасан',
        breadcrumb: [
          {
            text: 'Ажил бүртгэх /Гадагшаа дуудсан/',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inbound/:phone',
      name: 'homeInbound',
      component: () => import('@/views/home/HomeInbound.vue'),
      meta: {
        pageTitle: 'Дуудлага авах хуудас',
        breadcrumb: [
          {
            text: 'Ажил бүртгэх /Гаднаас дуудсан/',
            active: true,
          },
        ],
      },
    },
    {
      path: '/outbound/:phone',
      name: 'homeOutbound',
      component: () => import('@/views/home/HomeOutbound.vue'),
      meta: {
        pageTitle: 'Гадагшаа залгасан',
        breadcrumb: [
          {
            text: 'Ажил бүртгэх /Гадагшаа дуудсан/',
            active: true,
          },
        ],
      },
    },
    {
      path: '/statistic',
      name: 'statistic',
      component: () => import('@/views/home/card-statistic/CardStatistic.vue'),
      meta: {
        pageTitle: 'Statistics Cards',
        breadcrumb: [
          {
            text: 'Card',
          },
          {
            text: 'Statistics Cards',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- AUTHENTICATION ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/authentication/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    // *===============================================---*
    // *--------- ACCOUNT SETTING ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/account-setting',
      name: 'pages-account-setting',
      component: () => import('@/views/account-setting/AccountSetting.vue'),
      meta: {
        pageTitle: 'Бүртгэлийн тохиргоо',
        breadcrumb: [
          // {
          //   text: 'Pages',
          // },
          {
            text: 'Бүртгэлийн тохиргоо',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- REF_CATEGORY ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/ref-categories/list',
      name: 'ref-categories-list',
      component: () => import('@/views/ref-category-management/ref-categories-list/RefCategoriesList.vue'),
      meta: {
        pageTitle: 'Ангилалын жагсаалт',
        breadcrumb: [
          {
            text: 'Ангилалын жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- REF_PRODUCT ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/ref-products/list',
      name: 'ref-products-list',
      component: () => import('@/views/ref-product-management/ref-products-list/RefProductsList.vue'),
      meta: {
        pageTitle: 'Бүтээгдэхүүний жагсаалт',
        breadcrumb: [
          {
            text: 'Бүтээгдэхүүний жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- REF_STATUS ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/ref-statuses/list',
      name: 'ref-statuses-list',
      component: () => import('@/views/ref-status-management/ref-statuses-list/RefStatusesList.vue'),
      meta: {
        pageTitle: ' Төлвийн жагсаалт',
        breadcrumb: [
          {
            text: 'Төлвийн жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- REF_PRIORITY ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/ref-priorities/list',
      name: 'ref-priorities-list',
      component: () => import('@/views/ref-priority-management/ref-priorities-list/RefPrioritiesList.vue'),
      meta: {
        pageTitle: ' Ач холбогдлийн жагсаалт',
        breadcrumb: [
          {
            text: ' Ач холбогдлийн жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- REF_TAG ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/ref-tags/list',
      name: 'ref-tags-list',
      component: () => import('@/views/ref-tag-management/ref-tags-list/RefTagsList.vue'),
      meta: {
        pageTitle: 'Таг жагсаалт',
        breadcrumb: [
          {
            text: 'Таг жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- ORGANIZATION ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/organizations/list',
      name: 'organizations-list',
      component: () => import('@/views/organization-management/organizations-list/OrganizationsList.vue'),
      meta: {
        pageTitle: 'Байгууллагын жагсаалт',
        breadcrumb: [
          {
            text: 'Байгууллагын жагсаалт',
            active: true,
          },
        ],
      },
    },
    {
      path: '/organizations/view/:id',
      name: 'organizations-view',
      component: () => import('@/views/organization-management/organizations-view/OrganizationsView.vue'),
      meta: {
        pageTitle: 'Байгууллагын мэдээллийн дэлгэрэнгүй',
        breadcrumb: [
          {
            text: 'Байгууллагын мэдээллийн',
            active: true,
          },
        ],
      },
    },
    {
      path: '/organizations/edit/:id',
      name: 'organizations-edit',
      component: () => import('@/views/organization-management/organizations-edit/OrganizationsEdit.vue'),
      meta: {
        pageTitle: 'Байгууллагын мэдээлэл өөрчлөх',
        breadcrumb: [
          {
            text: 'Байгууллагын мэдээлэл өөрчлөх',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- USER ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/users/list',
      name: 'users-list',
      component: () => import('@/views/user-management/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Бүртгэлийн жагсаалт',
        breadcrumb: [
          {
            text: 'Бүртгэлийн жагсаалт',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/user-management/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'Бүртгэлийн дэлгэрэнгүй',
        breadcrumb: [
          {
            text: 'Бүртгэлийн дэлгэрэнгүй',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/user-management/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Бүртгэл өөрчлөх',
        breadcrumb: [
          {
            text: 'Бүртгэл өөрчлөх',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
    // *===============================================---*
    {
      path: '/todo',
      name: 'todo',
      component: () => import('@/views/todo/Todo.vue'),
    },
    {
      path: '/todo-list',
      name: 'todo-list',
      component: () => import('@/views/todo/TodoList.vue'),
      meta: {
        contentClass: 'todo-application',
      },
    },
    {
      path: '/todo-history-list',
      name: 'todo-history-list',
      component: () => import('@/views/todo/TodoList.vue'),
      meta: {
        contentClass: 'todo-application',
      },
    },
    // {
    //   path: '/todo/:filter',
    //   name: 'todo-filter',
    //   component: () => import('@/views/todo/Todo.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'todo-application',
    //     navActiveLink: 'todo',
    //   },
    //   // beforeEnter(to, _, next) {
    //   //   if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
    //   //   else next({ name: 'error-404' })
    //   // },
    // },
    // {
    //   path: '/todo/tag/:tag',
    //   name: 'todo-tag',
    //   component: () => import('@/views/todo/Todo.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left',
    //     contentClass: 'todo-application',
    //     navActiveLink: 'todo',
    //   },
    //   beforeEnter(to, _, next) {
    //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
    //     else next({ name: 'error-404' })
    //   },
    // },
    // *===============================================---*
    // *--------- DRAG & DROP ---------------------------------------*
    // *===============================================---*
    {
      path: '/todo-board',
      name: 'todo-board',
      component: () => import('@/views/todo/TodoBoard.vue'),
        meta: {
        contentClass: 'kanban-application',
      },
    },
    {
      path: '/todo-history-board',
      name: 'todo-history-board',
      component: () => import('@/views/todo/TodoBoard.vue'),
        meta: {
        contentClass: 'kanban-application',
      },
    },
    {
      path: '/report',
      name: 'report',
      component: () => import('@/views/report/reports-list/ReportsList.vue'),
      meta: {
        pageTitle: 'Тайлан',
        breadcrumb: [
          {
            text: 'Тайлан',
            active: true,
          },
        ],
      }
    },
    {
      path: '/report-category',
      name: 'report-category',
      component: () => import('@/views/report/reports-list/ReportsList.vue'),
      meta: {
        pageTitle: 'Тайлан',
        breadcrumb: [
          {
            text: 'Тайлан',
            active: true,
          },
        ],
      }
    },
    {
      path: '/report-product',
      name: 'report-product',
      component: () => import('@/views/report/reports-list/ReportsList.vue'),
      meta: {
        pageTitle: 'Тайлан',
        breadcrumb: [
          {
            text: 'Тайлан',
            active: true,
          },
        ],
      }
    },
    {
      path: '/qpays-list',
      name: 'qpays-list',
      component: () => import('@/views/qpay/qpays-list/QpaysList.vue'),
      meta: {
        pageTitle: 'Qpay',
        breadcrumb: [
          {
            text: 'Qpay',
            active: true,
          },
        ],
      }
    },
    {
      path: '/ebarimts-list',
      name: 'ebarimts-list',
      component: () => import('@/views/ebarimt/ebarimts-list/EbarimtsList.vue'),
      meta: {
        pageTitle: 'Ebarimt',
        breadcrumb: [
          {
            text: 'И-баримт',
            active: true,
          },
        ],
      }
    },
    // *===============================================---*
    // *--------- ROLE ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/roles/list',
      name: 'roles-list',
      component: () => import('@/views/role-management/roles-list/RolesList.vue'),
      meta: {
        pageTitle: 'Үүргийн жагсаалт',
        breadcrumb: [
          {
            text: 'Үүргийн жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- MENU ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/menus/list',
      name: 'menus-list',
      component: () => import('@/views/ref-status-management/ref-statuses-list/RefStatusesList.vue'),
      meta: {
        pageTitle: ' Цэсний жагсаалт',
        breadcrumb: [
          {
            text: 'Цэсний жагсаалт',
            active: true,
          },
        ],
      },
    },
    // *===============================================---*
    // *--------- ROLE ---- ---------------------------------------*
    // *===============================================---*
    {
      path: '/menu-group/list',
      name: 'menu-groups-list',
      component: () => import('@/views/ref-status-management/ref-statuses-list/RefStatusesList.vue'),
      meta: {
        pageTitle: 'Цэсний бүлгийн жагсаалт',
        breadcrumb: [
          {
            text: 'Цэсний бүлгийн жагсаалт',
            active: true,
          },
        ],
      },
    },
    {
      path: '/excel-upload',
      name: 'excel-upload',
      component: () => import('@/views/excel/ExcelUpload.vue'),
      meta: {
        contentClass: 'kanban-application',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  console.log('to', to, canNavigate(to))
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  console.log('test2', to.meta.redirectIfLoggedIn)
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    console.log('userData11', userData)
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
