import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// Composition API
Vue.use(VueCompositionAPI)
import ElementUI from 'element-ui';
import '@/assets/css/theme/index.css';
import mn from 'element-ui/lib/locale/lang/mn'
import locale from 'element-ui/lib/locale'
locale.use(mn)
Vue.use(ElementUI);

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// test cookietei ajillah
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline)

import VueCookies from 'vue-cookies'
Vue.config.ignoredElements = ['iframe'];
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '7d'})

import VueNativeNotification from 'vue-native-notification'

Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)


// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
